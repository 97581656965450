import eventBus from '@/services/EventBus';
export const blueMutations = {
    SET_PRICE_BARS(state, priceBars) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
        state.priceBars = priceBars;
        state.availableFilters = {
            conditions: (_b = (_a = state.availableFilters) === null || _a === void 0 ? void 0 : _a.conditions) !== null && _b !== void 0 ? _b : [],
            brands: (_d = (_c = state.availableFilters) === null || _c === void 0 ? void 0 : _c.brands) !== null && _d !== void 0 ? _d : [],
            isDeal: (_f = (_e = state.availableFilters) === null || _e === void 0 ? void 0 : _e.isDeal) !== null && _f !== void 0 ? _f : false,
            newlyArrived: (_h = (_g = state.availableFilters) === null || _g === void 0 ? void 0 : _g.newlyArrived) !== null && _h !== void 0 ? _h : false,
            priceRange: {
                start: (_k = (_j = [...priceBars].shift()) === null || _j === void 0 ? void 0 : _j.minPrice) !== null && _k !== void 0 ? _k : 0,
                end: (_m = (_l = [...priceBars].pop()) === null || _l === void 0 ? void 0 : _l.maxPrice) !== null && _m !== void 0 ? _m : 0,
            },
        };
        eventBus.emit('priceRange:loaded', {
            min: (_p = (_o = [...priceBars].shift()) === null || _o === void 0 ? void 0 : _o.minPrice) !== null && _p !== void 0 ? _p : 0,
            max: (_r = (_q = [...priceBars].pop()) === null || _q === void 0 ? void 0 : _q.maxPrice) !== null && _r !== void 0 ? _r : 0,
        });
    },
    SET_AVAILABLE_FILTERS(state, availableFilters) {
        var _a, _b, _c, _d;
        state.availableFilters = {
            conditions: availableFilters.conditions,
            brands: availableFilters.brands,
            isDeal: availableFilters.hasDeals,
            priceRange: (_b = (_a = state.availableFilters) === null || _a === void 0 ? void 0 : _a.priceRange) !== null && _b !== void 0 ? _b : null,
            newlyArrived: (_d = (_c = state.availableFilters) === null || _c === void 0 ? void 0 : _c.newlyArrived) !== null && _d !== void 0 ? _d : false,
        };
    },
    SET_RECOS(state, recos) {
        const castedProductRecos = [];
        recos.forEach((emarsysProduct) => {
            const castedProductReco = {
                id: 0,
                cArtNr: '',
                productUuid: null,
                modelId: emarsysProduct.id,
                name: emarsysProduct.title,
                ean: '',
                mainImage: emarsysProduct.image,
                condition: '',
                numericCondition: 0,
                stockAvailable: emarsysProduct.available ? 100 : 0,
                category: 0,
                categoryTree: [],
                categoryPath: '',
                categoryPathBlue: '',
                breadcrumb: [],
                manufacturer: emarsysProduct.brand ? emarsysProduct.brand : '',
                noob: false,
                currentPrice: emarsysProduct.c_deal_price ? emarsysProduct.c_deal_price : emarsysProduct.price,
                percent: 20,
                dealPrice: emarsysProduct.c_deal_price,
                priceNetto: 0,
                originalPrice: emarsysProduct.c_deal_msrp ? emarsysProduct.c_deal_msrp : emarsysProduct.price,
                taxRate: 0,
                attributes: {},
                link: emarsysProduct.link,
                images: [],
                parsedDescription: '',
                shortDescription: null,
                countdownEnd: null,
                co2: 0,
                firstLevelCatId: 0,
                klarnaRate: '',
                countKlarnaRates: 0,
                isNewcomer: false,
            };
            castedProductRecos.push(castedProductReco);
        });
        state.recos = castedProductRecos;
    },
    SET_CART(state, cart) {
        state.cart = cart;
    },
    SET_CHECKOUT_IS_IN_PROGRESS(state, progress) {
        state.checkout_is_in_progress = progress;
    },
    SET_SHIPPING_OPTIONS(state, shipping_options) {
        state.shipping_options = shipping_options;
    },
    SET_PAYMENT_TYPES(state, payment_types) {
        state.payment_types = payment_types;
    },
    SET_COUPONS(state, coupons) {
        state.coupons = coupons;
    },
    SET_VALID_CART(state, toggle) {
        state.valid_for_checkout = toggle;
    },
    SET_ADDITIONAL_PAYMENT_INFO(state, additional_info) {
        state.additional_payment_info = additional_info;
    },
    SET_CART_ERRORS(state, errors) {
        state.cartErrors = errors;
    },
    RESET_CART_ERRORS(state) {
        state.cartErrors = {};
    },
    SET_SHOULD_REGISTER(state, shouldRegister) {
        state.guest_registration_data.shouldRegister = shouldRegister;
        if (!shouldRegister) {
            state.guest_registration_data.password = '';
        }
    },
    SET_REGISTRATION_PASSWORD(state, password) {
        state.guest_registration_data.password = password;
    },
    SET_BLUE_PAYMENTS(state, payload) {
        state.bluePaymentMethods = payload;
    },
    SET_CURRENT_PRODUCTS(state, products) {
        state.currentProducts = products;
    },
    PATCH_PAGEINFO(state, pageInfo) {
        state.currentPageInfo = pageInfo;
    },
    SET_CURRENT_PRODUCT(state, product) {
        state.currentProduct = product;
    },
    SET_FILTERS(state, filters) {
        state.appliedFilters = filters;
    },
    SET_LISTING(state, payload) {
        state.listingType = payload.listingType;
        state.listingTypeValue = payload.listingValue;
    },
    SET_CURRENT_PAGE(state, newCurrentPage) {
        if (!state.currentPageInfo) {
            return;
        }
        state.currentPageInfo.current_page = newCurrentPage;
    },
    SET_DEALS(state, deals) {
        if (state.appliedFilters === null) {
            return;
        }
        state.appliedFilters.isDeal = deals;
    },
    SET_NEWLY_ARRIVED(state, newlyArrived) {
        if (state.appliedFilters === null) {
            return;
        }
        state.appliedFilters.newlyArrived = newlyArrived;
    },
    SET_CURRENT_SORTING(state, currentSorting) {
        state.currentSorting = currentSorting;
    },
    SET_PRICE_RANGE(state, priceRange) {
        if (state.appliedFilters === null) {
            return;
        }
        state.appliedFilters.priceRange = priceRange;
    },
    INCREASE_LISTING_REQUEST_COUNTER(state, increment) {
        state.currentBlueListingRequest += increment;
    },
};
