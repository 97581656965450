var _a, _b;
import eventBus from '@/services/EventBus';
import httpClient from '@/services/api/axios';
import modelTransform from '@/helpers/modelTransform';
import { store, useStore } from '@/store';
import popupManager from '@/services/Dom/PopupManager';
import * as Vue from 'vue/dist/vue.esm-bundler';
import App from '@/App.vue';
import SlideHeaderMobile from '@/components/Header/SlideHeaderMobile.vue';
import WelcomeUser from '@/components/Account/WelcomeUser.vue';
import BlueCartIndicator from '@/components/Header/BlueCartIndicator.vue';
import HeaderAdvantageEntry from '@/components/Header/HeaderAdvantageEntry.vue';
import FaqDisplay from '@/components/Base/FAQ/FaqDisplay.vue';
import CouponPopup from '@/components/Base/Coupons/CouponPopup.vue';
import CartDisplaySmall from '@/components/Blue/Cart/CartDisplaySmall.vue';
import GoogleMapsNavigationButton from '@/components/Locations/subComponents/GoogleMapsNavigationButton.vue';
import StandardStaticHeader from '@/components/StaticPages/StandardStaticHeader.vue';
import StaticNav from '@/components/StaticPages/StaticNav/StaticNav.vue';
import ArticleCardsWithMoreButton from '@/components/StaticPages/Press/ArticleCardsWithMoreButton.vue';
import elementHelper from '@/helpers/documentHelper';
import PaymentMethods from '@/components/Blue/Payment/PaymentMethods.vue';
import PaymentMethodsInfos from '@/components/Blue/Payment/PaymentMethodsInfos.vue';
import RepairRequestForm from '@/components/Base/Forms/RepairRequestForm.vue';
import lax from 'lax.js';
import EmarsysRecos from '@/components/Blue/ProductDetailPage/Modules/EmarsysRecos.vue';
import LogosKnownBy from '@/components/StaticPages/LogosKnownBy.vue';
import AwardsWithMobileSlider from '@/components/Base/Slider/AwardsWithMobileSlider.vue';
import AwardsFullSlider from '@/components/Base/Slider/AwardsFullSlider.vue';
import FullSliderVue from '@/components/Base/Slider/FullSliderVue.vue';
import IsoCertification from '@/components/StaticPages/IsoCertification.vue';
import LogosOurPartners from '@/components/StaticPages/LogosOurPartners.vue';
import SearchBar from '@/components/Base/SearchBar.vue';
import PasswordResetForm from '@/components/Base/Forms/PasswordResetForm.vue';
// Popups:
import OpenPopupButton from '@/components/PopUps/OpenPopupButton.vue';
import ConditionsPopup from '@/components/PopUps/ConditionsPopup/ConditionsPopup.vue';
import ContactPopup from '@/components/PopUps/ContactPopup/ContactPopup.vue';
import BlueDropdownMenu from '@/components/Header/BlueDropdownMenu.vue';
import LoginPopup from '@/components/PopUps/AuthPopup/LoginPopup.vue';
import LoginForm from '@/components/PopUps/AuthPopup/LoginForm.vue';
import RegisterPopup from '@/components/PopUps/AuthPopup/RegisterPopup.vue';
import RegisterForm from '@/components/PopUps/AuthPopup/RegisterForm.vue';
import InfoPopup from '@/components/PopUps/InfoPopup.vue';
import CustomerSupportInfo from '@/components/Base/CustomerSupportInfo.vue';
import DesktopMenu from '@/components/Menus/DesktopMenu.vue';
import MenuItems from '@/components/Menus/MenuItems.vue';
import MyAccountMenuItems from '@/components/Menus/MyAccountMenuItems.vue';
import MobileSidebarMenu from '@/components/Menus/MobileMenus/MobileSidebarMenu.vue';
import MobileSidebarMyAccountMenu from '@/components/Menus/MobileMenus/MobileSidebarMyAccountMenu.vue';
import TrustamiWidget from '@/components/Base/TrustamiWidget.vue';
import '@style/app_base';
import '@style/app';
import '@/bootstrap';
//  vuetify
import vuetify from '@/vuetify';
import ContactFormGoecoImHandel from '@/components/GoEco/GoecoImHandel/ContactFormGoecoImHandel.vue';
import ContactForm from '@/components/b2bPages/_gemeinsam_verwendeter_content/ContactForm.vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
// @see templates/twig/myswooop2019/layout.html.twig
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const captchaSitekey = (_b = (_a = document.getElementById('CAPTCHA-SITEKEY')) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
const app = Vue.createApp(App)
    .use(vuetify)
    .use(VueReCaptcha, {
    siteKey: captchaSitekey,
    loaderOptions: {
        autoHideBadge: true,
    },
});
app.use(store);
app.component('slide-header-mobile', SlideHeaderMobile);
app.component('welcome-user', WelcomeUser);
app.component('blue-cart-indicator', BlueCartIndicator);
app.component('header-advantage-entry', HeaderAdvantageEntry);
app.component('faq-display', FaqDisplay);
app.component('coupon-popup', CouponPopup);
app.component('blue-cart-display-small', CartDisplaySmall);
app.component('google-maps-navigation-button', GoogleMapsNavigationButton);
app.component('standard-static-header', StandardStaticHeader);
app.component('static-nav', StaticNav);
app.component('article-cards-with-more-button', ArticleCardsWithMoreButton);
app.component('blue-payments', PaymentMethods);
app.component('blue-payments-info', PaymentMethodsInfos);
app.component('repair-request-form', RepairRequestForm);
app.component('password-reset-form', PasswordResetForm);
app.component('emarsys-recos', EmarsysRecos);
app.component('logos-known-by', LogosKnownBy);
app.component('awards-width-mobile-slider', AwardsWithMobileSlider);
app.component('awards-full-slider', AwardsFullSlider);
app.component('full-slider-vue', FullSliderVue);
app.component('logos-our-partners', LogosOurPartners);
app.component('iso-certification', IsoCertification);
app.component('search-bar', SearchBar);
app.component('blue-dropdown-menu', BlueDropdownMenu);
app.component('login-popup', LoginPopup);
app.component('login-form', LoginForm);
app.component('register-popup', RegisterPopup);
app.component('register-form', RegisterForm);
app.component('contact-form-goeco-im-handel', ContactFormGoecoImHandel);
app.component('customer-support-info', CustomerSupportInfo);
app.component('open-popup-button', OpenPopupButton);
app.component('conditions-popup', ConditionsPopup);
app.component('contact-popup', ContactPopup);
app.component('info-popup', InfoPopup);
app.component('contact-form', ContactForm);
app.component('my-account-menu-items', MyAccountMenuItems);
app.component('menu-items', MenuItems);
app.component('mobile-sidebar-menu', MobileSidebarMenu);
app.component('mobile-sidebar-my-account-menu', MobileSidebarMyAccountMenu);
app.component('desktop-menu', DesktopMenu);
app.component('trustami-widget', TrustamiWidget);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.axios = httpClient;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.modelTransform = modelTransform;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.useStore = useStore;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.elementHelper = elementHelper;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.eventBus = eventBus;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.popupManager = popupManager;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.lax = lax;
require('@/bootstrap/events');
require('@legacy/general.js');
require('@legacy/jquery_extends.js');
require('@legacy/npy-scorecount.js');
require('@legacy/swal.js');
require('@legacy/xtended_swal.js');
require('@legacy/emarsys_recommends.js');
require('@legacy/fancySelectField.js');
require('@legacy/forms.js');
require('@legacy/infomessages.js');
require('@legacy/modal.js');
require('@legacy/xxx_application.js');
require('@legacy/header.js');
export default app;
