export const blueState = {
    cart: null,
    cartErrors: {},
    checkout_is_in_progress: false,
    shipping_options: [],
    payment_types: [],
    coupons: null,
    valid_for_checkout: null,
    additional_payment_info: null,
    guest_registration_data: {
        password: '',
        shouldRegister: false,
    },
    bluePaymentMethods: [],
    currentProduct: null,
    recos: null,
    currentProducts: [],
    appliedFilters: {
        conditions: [],
        brands: [],
        priceRange: null,
        isDeal: false,
        newlyArrived: false,
    },
    availableFilters: null,
    priceBars: [],
    listingType: null,
    listingTypeValue: null,
    currentPageInfo: null,
    currentSorting: 'scoring',
    currentBlueListingRequest: 0,
};
