import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import optimizeImage from '@/services/ImageOptimize';
export default defineComponent({
    name: 'awards-width-mobile-slider',
    methods: { optimizeImage },
    data() {
        return {
            logos: [
                {
                    path: '/images/icons/wachstum.png',
                    link: 'https://www.businessinsider.de/themen/rebuy/#:~:text=Mit%20diesem%20Konzept%20belegte%20MySwooop%20Platz%2025%20im%20Gr%C3%BCnderszene%2DRanking.',
                    alt: 'Wachstums-Ranking',
                    title: 'Badge Wachstums-Ranking Die 50 Wachstumssieger Platz 25',
                },
                {
                    path: '/images/_new_brand/logos/logo_trustami_new.png',
                    link: 'https://www.trustami.com/erfahrung/myswooop-de-bewertung',
                    alt: 'Trustami Bewertung',
                    title: 'Zur Trustami Bewertung - Ausgezeichnet *****',
                },
                {
                    path: '/images/handelsblatt.png',
                    link: 'https://www.handelsblatt.com/downloads/24126434/8/ranking-pdf-vorlage_beste-haendler_online.pdf',
                    alt: 'Handelsblatt-Badge',
                    title: 'Handelsblatt-Badge Deutschlands beste Online-Händler 1. Platz',
                },
                /*
                {
                    path: '/images/icons/trustpilot_badge.png',
                    link: 'https://de.trustpilot.com/review/myswooop.de',
                    alt: 'Trustpilot',
                    title: 'Trustpilot-Badge Bewertung: Hervorragend',
                },
                 */
                {
                    path: '/images/_new_brand/logos/mySWOOOP_German_Brand_Award_2023_Gold_sm.png',
                    link: '/presse/pressemitteilung/117',
                    alt: 'German Brand Award',
                    title: 'MySWOOOP ausgezeichnet mit dem German Brand Award',
                },
            ],
        };
    },
    computed: Object.assign({}, mapGetters('global', {
        isMobile: 'isMobile',
    })),
});
